import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chatUsersSort'
})
export class ChatUsersSortPipe implements PipeTransform {

  transform(users: any, ...args: unknown[]): unknown {
      users = users.sort((a,b) => a.unreadMessages > b.unreadMessages ? 1 : -1);
      return users;
  }

}
