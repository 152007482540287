import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CustomChart1Component } from './custom-chart1/custom-chart1.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {GeniusSettingsComponent} from './genius-settings/genius-settings.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MaterialModule} from '../material/material.module';
import { InfoDialogComponent } from './info-dialog/info-dialog/info-dialog.component';
import {CoreModule} from '../core/core.module';
import {TourMatMenuModule} from 'ngx-ui-tour-md-menu';
import { ElectrodeContactDialogComponent } from './electrode-contact-dialog/electrode-contact-dialog.component';
import { ChatWidgetComponent } from './chat-widget/chat-widget.component';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import { FilePreviewComponent } from './chat-widget/file-preview/file-preview.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {ChatUsersSortPipe} from '../pipes/chat-users-sort.pipe';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { BindSensorComponent } from './genius-settings/bind-sensor/bind-sensor.component';
import {DeviceIdPipe} from "../pipes/device-id.pipe";



@NgModule({
  declarations: [CustomChart1Component, GeniusSettingsComponent, InfoDialogComponent, ElectrodeContactDialogComponent, ChatWidgetComponent, FilePreviewComponent, ChatUsersSortPipe, BindSensorComponent, DeviceIdPipe],
    imports: [
        CommonModule,
        NgxChartsModule,
        MatIconModule,
        MatButtonModule,
        FlexLayoutModule,
        TranslateModule,
        MatProgressSpinnerModule,
        MaterialModule,
        CoreModule,
        TourMatMenuModule,
        PickerModule,
        PdfViewerModule,
        NgbTooltipModule,
    ],
  exports: [
    GeniusSettingsComponent, InfoDialogComponent, ChatWidgetComponent, BindSensorComponent, DeviceIdPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [InfoDialogComponent, FilePreviewComponent, BindSensorComponent]
})
export class ComponentsModule { }
