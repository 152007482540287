<div class="expand-chart" [ngClass]="isUnreadMessages? 'shake-chart' : null" *ngIf="chatStyle === 'collapsed'" (click)="expandChart()">
  <img [src]="isUnreadMessages? 'assets/chat/chat-icon-red.png' : 'assets/chat/chat-icon-white.png'" alt="chat-icon">
</div>

<div id="chat" class="chat-container" [hidden]="chatStyle === 'collapsed'" [@expandChat]="chatStyle === 'expanded' ? 'expanded' : 'collapsed'">
  <div class="collapse-chart" (click)="collapseChart()">
    <span class="material-symbols-outlined collapse-icon">
      close
    </span>
  </div>
  <div class="supervisors-list">
    <div class="supervisor-item" [ngClass]="chatUser && chatUser.id === supervisor.id ? 'supervisor-item-active' : null" *ngFor="let supervisor of allowedSupervisors | chatUsersSort" (click)="setChatUser(supervisor)">
      <ng-container *ngIf="supervisor.id !== '18212744'">
        <div class="supervisor-image">
          <img [src]="supervisor.photoURL" alt="img">
          <span [ngClass]="{ 'circle-online': supervisor.status === 'online', 'circle-offline': supervisor.status === 'away', 'circle-unknown': !supervisor.status }" class="status-circle"></span>
          <div class="unread-messages-count" *ngIf="supervisor.unreadMessages > 0">
            <span>{{supervisor.unreadMessages}}</span>
          </div>
        </div>
        <div class="supervisor-details">
          <p class="email">{{supervisor.email}}</p>
          <p>({{supervisor.displayName}})</p>
          <p *ngIf="supervisor.status === 'away'">{{'CHAT_WIDGET.ACTIVE' | translate}} {{getTimeSince(supervisor.lastSeen)}}</p>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="chat-window" [hidden]="!chatUser" [@expandChat]="chatUser ? 'expanded' : 'collapsed'">
    <div class="chat-header" *ngIf="chatUser">
      <div class="chat-user">
        <p class="email">{{chatUser.email}}</p>
        <p>({{chatUser.displayName}})</p>
        <div class="status">
          <div *ngIf="chatUser.status === 'online'">
            <span class="status-circle circle-online"></span>
            <span>{{'CHAT_WIDGET.ACTIVE' | translate}}</span>
          </div>
          <div *ngIf="chatUser.status === 'away'">
            <span class="status-circle circle-offline"></span>
            <span>{{'CHAT_WIDGET.AWAY' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="search-trigger" *ngIf="!showSearchField" (click)="triggerSearchField()">
        <mat-icon class="search-icon">search</mat-icon>
      </div>
      <div class="chat-search" *ngIf="showSearchField" [@enterAnimation]>
        <div class="search-field">
          <mat-form-field>
            <input [formControl]="searchValue" matInput type="text" [placeholder]="'CHAT_WIDGET.SEARCH_TEXT' | translate">
            <button mat-button matPrefix mat-icon-button>
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="search-results">
            <p>{{'CHAT_WIDGET.SEARCH_MESSAGES' | translate}} {{chatRoom.messages.length}}</p>
            <mat-icon class="close-icon" (click)="hideSearchField()">close</mat-icon>
        </div>
      </div>
    </div>
<!--      <div class="chat-templates" *ngIf="currentUser?.role === 'normal'">-->
<!--        <div class="suggestions" [ngClass]="{'suggestions-closed': !showSuggestions}" (click)="toggleSuggestions()">-->
<!--          <span class="material-symbols-outlined info-icon" [ngbTooltip]="suggestionsTooltip()">-->
<!--            info-->
<!--          </span>-->
<!--          <h6>{{'CHAT_WIDGET.SUGGESTIONS' | translate}}</h6>-->
<!--          <span class="material-symbols-outlined" [ngClass]="{'info-icon-rotate': showSuggestions}" >-->
<!--            keyboard_arrow_down-->
<!--          </span>-->
<!--        </div>-->
<!--        <div class="templates-list"  [@expandSuggestions]="showSuggestions ? 'expanded' : 'collapsed'">-->
<!--          <ng-container *ngFor="let template of chatTemplates">-->
<!--            <div class="template" *ngIf="template.language === getCurrentLanguage()">-->
<!--              <span class="template-title" (click)="sendMessageFromSuggestions(template.messageText)">{{template.messageTitle}}</span>-->
<!--            </div>-->
<!--          </ng-container>-->
<!--        </div>-->
<!--      </div>-->
    <div class="chat-messages-list" *ngIf="chatUser" #messagesList (click)="hideEmojiPicker()">
      <ng-container *ngFor="let message of chatRoom.messages">
        <div class="message-block" *ngIf="message.senderId" (click)="hideEmojiPicker()">
          <div class="message-block-wrapper" [ngClass]="message.senderId === currentUser.id ? 'block-sender' : 'block-incoming'">
            <div class="message-info" [ngClass]="message.senderId === currentUser.id ? 'info-sender' : 'info-incoming'">
              <span class="message-date">{{transformDate(message.date)}}</span>
              <img class="sender-img" *ngIf="message.senderId === currentUser.id" [src]="currentUser.photoURL" alt="img">
              <img class="sender-img" *ngIf="message.senderId !== currentUser.id" [src]="chatUser.photoURL" alt="img">
            </div>
            <div class="message-text-wrap">
              <p>{{message.text}}</p>
            </div>
            <div class="message-file-preview" *ngIf="message.file && message.file.fileRef">
              <p class="file-name">{{message.file.fileName}}</p>
              <span class="material-symbols-outlined preview-icon" *ngIf="isValidPreviewType(message.file.fileName)" (click)="previewFile(message.file)">
                visibility
              </span>
              <span class="material-symbols-outlined download-icon" (click)="downloadFile(message.file)">
                cloud_download
              </span>
            </div>
            <div class="supervisor-comment" *ngIf="message.commentLink"  (click)="navigateToRecord(message)">
              <p>{{message.commentLink.text}}</p>
              <span class="material-symbols-outlined preview-icon link-icon">
                link
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="chat-input-actions" *ngIf="searchValueLength === 0">
      <div class="input-wrap">
        <form (keydown)="handleEnterEvent($event)">
          <mat-form-field>
            <mat-label>{{'CHAT_WIDGET.MESSAGE' | translate}}</mat-label>
            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3" [ngModel]="messageText" (input)="setMessageText($event.target.value)" name="message"></textarea>
          </mat-form-field>
        </form>
        <div class="emoji-icon" (click)="toggleEmojiPicker()">
          <span class="material-symbols-outlined">
            sentiment_satisfied
          </span>
        </div>
        <div class="emoji-wrap">
          <emoji-mart [i18n]="getEmojiTranslations()" id="emoji-window" class="emoji-window" [style]="{ position: 'absolute', bottom: '20px', right: '55px' }" *ngIf="showEmoji" (emojiClick)="addEmoji($event)" [darkMode]="false"></emoji-mart>
        </div>
        <div class="upload-file-wrap">
          <label class="custom-file-upload">
            <input type="file" [multiple]="false" [accept]="fileExtensions" (change)="uploadFile($event)"/>
            <span class="material-symbols-outlined">
            attach_file
          </span>
          </label>
        </div>
      </div>
      <button mat-raised-button color="primary" type="submit" class="send-button" [disabled]="!messageText && !file" (click)="sendMessage()">{{'CHAT_WIDGET.SEND' | translate}}</button>
    </div>
    <div class="progress-container" *ngIf="file">
      <mat-progress-bar mode="determinate" [value]="uploadProgress"></mat-progress-bar>
    </div>
    <div class="file-preview" *ngIf="file">
      <p>{{file.name}} ({{fileSizeInMb(file.size)}})</p>
      <span class="material-symbols-outlined delete-file" (click)="deleteFile()">
        close
      </span>
    </div>
  </div>
</div>
