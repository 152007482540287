import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {IUser} from '../../core/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class LimitSessionsService {

  constructor(
    private afs: AngularFirestore,
  ) { }
  setUserMonthSessions(user: IUser) {
    return this.afs.doc(`users/${user.uid}`).set(user);
  }
  getUserMonthSessions(uid: string) {
    return this.afs.doc(`users/${uid}`).valueChanges();
  }
  calculateHoursBetweenDates(date1, date2) {
    const date1Millis = new Date(date1).getTime();
    const date2Millis = new Date(date2).getTime();
    const differenceInMillis = Math.abs(date2Millis - date1Millis);
    return differenceInMillis / (1000 * 60 * 60);
  }

  calculateUserSessionsPerDay(sessions: any[]) {
    return sessions.reduce((accumulator, currentObject) => {
      return accumulator + currentObject.seconds;
    }, 0);
  }
}
