import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {filter, take, takeUntil} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import * as objectPath from 'object-path';
// Layout
import {LayoutConfigService, MenuAsideService, MenuOptions, OffcanvasOptions, TranslationService} from '../../../core/_base/layout';
import {HtmlClassService} from '../html-class.service';
import {TranslateService} from '@ngx-translate/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {NavigationService} from '../../../services/navigation/navigation.service';
import {BrandComponent} from '../brand/brand.component';
import {select, Store} from '@ngrx/store';
import {ASIDE_CHARTS, ASIDE_TEST, ASIDE_TRAINING_CONFIG} from '../../../core/consts/aside-menu-variables';
import {AppState} from '../../../core/reducers';
import {IUser} from '../../../core/interfaces/user';
import {currentUser} from '../../../core/auth';
import {CoachmarkService} from '../../../services/coachmark/coachmark.service';
import {ProjectStepsService} from '../../../services/project-steps/project-steps.service';
import {CurrentUserService} from '../../../services/current-user/current-user.service';
import {PATHS} from '../../../core/_config/route.config';
import {Subject} from 'rxjs';

@Component({
  selector: 'kt-aside-left',
  templateUrl: './aside-left.component.html',
  styleUrls: ['./aside-left.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsideLeftComponent implements OnInit, AfterViewInit {
  private offcanvas: any;

  @ViewChild('asideMenuOffcanvas', {static: true}) asideMenuOffcanvas: ElementRef;
  @ViewChild('asideMenu', {static: true}) asideMenu: ElementRef;

  asideLogo = '';
  asideClasses = '';
  currentRouteUrl = '';
  insideTm: any;
  outsideTm: any;
  chartType: string;
  showMuscleLinks = false;
  today: number = Date.now();
  showCoachMark = true;

  menuCanvasOptions: OffcanvasOptions = {
    baseClass: 'aside',
    overlay: true,
    closeBy: 'kt_aside_close_btn',
    toggleBy: {
      target: 'kt_aside_mobile_toggle',
      state: 'mobile-toggle-active'
    }
  };

  menuOptions: MenuOptions = {
    // submenu setup
    submenu: {
      desktop: {
        // by default the menu mode set to accordion in desktop mode
        default: 'dropdown',
      },
      tablet: 'accordion', // menu set to accordion in tablet mode
      mobile: 'accordion' // menu set to accordion in mobile mode
    },

    // accordion setup
    accordion: {
      expandAll: false // allow having multiple expanded accordions in the menu
    }
  };
  details: any;
  currentUser: IUser;
  currentRolePath: any;
  unsubscribe = new Subject()
  /**
   * Component Constructor
   *
   * param htmlClassService: HtmlClassService
   * param menuAsideService
   * param layoutConfigService: LayoutConfigService
   * param router: Router
   * param render: Renderer2
   * param cdr: ChangeDetectorRef
   */
  constructor(
    public htmlClassService: HtmlClassService,
    public menuAsideService: MenuAsideService,
    public layoutConfigService: LayoutConfigService,
    private router: Router,
    private render: Renderer2,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private afs: AngularFirestore,
    private firebaseUser: CurrentUserService,
    private projectSteps: ProjectStepsService,
    private translations: TranslationService,
  ) {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.afs
      .collection('links')
      .snapshotChanges()
      .subscribe((values) => {
        const obj = {};
        values.forEach((d: any) => {
          obj[d.payload.doc.id] = {
            id: d.payload.doc.id,
            ...d.payload.doc.data(),
          };
        });
        this.details = obj[this.translate.getDefaultLang()];
      });
    this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
        this.mobileMenuClose();
        this.cdr.markForCheck();
      });


    const config = this.layoutConfigService.getConfig();

    if (objectPath.get(config, 'aside.menu.dropdown')) {
      this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-dropdown', '1');
      // tslint:disable-next-line:max-line-length
      this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-dropdown-timeout', objectPath.get(config, 'aside.menu.submenu.dropdown.hover-timeout'));
    }

    this.asideClasses = this.htmlClassService.getClasses('aside', true).toString();
    this.asideLogo = this.getAsideLogo();
    setTimeout(() => {
      this.offcanvas = new KTOffcanvas(this.asideMenuOffcanvas.nativeElement, this.menuCanvasOptions);
    });
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.firebaseUser.getCurrentUser().pipe(takeUntil(this.unsubscribe)).subscribe((user: IUser) => {
      this.currentUser = user;
      if (user.role === 'super user' || user.role === 'supervisor') {
        this.currentRolePath = PATHS[user.role].routes;
        this.cdr.markForCheck();
        return;
      }
      this.projectSteps.checkRoutes(user.id).pipe(takeUntil(this.unsubscribe)).subscribe((data:any) => {
        if (user.role === 'premium') {
          user.role = 'normal';
        }
        const routes = PATHS[user.role].routes;
        routes.find(elem => elem.translate === 'MENU.TEST')
          .isAvailable = user.billing && user.survey

        routes.find(elem => elem.translate === 'MENU.TRAINING')
          .isAvailable = routes.find(elem => elem.translate === 'MENU.TEST').isAvailable
          && data.some(item => item.chartType === 'MENU.TEST');

        routes.find(elem => elem.translate === 'MENU.ANALYSIS')
          .isAvailable = routes.find(elem => elem.translate === 'MENU.TRAINING').isAvailable
          && data.some(item => item.chartType === 'MENU.TRAINING_SCREEN');

        routes.find(elem => elem.translate === 'MENU.STATISTIC')
          .isAvailable = routes.find(elem => elem.translate === 'MENU.TRAINING').isAvailable
          && data.some(item => item.chartType === 'MENU.TRAINING_SCREEN');

        if (routes.find(elem => elem.translate === 'MENU.STATISTIC').isAvailable) {
          this.unsubscribe.complete();
        }
        this.currentRolePath = routes;
        this.cdr.markForCheck();
      });
    });
  }

  getAsideLogo() {
    let result = 'logo-light.png';
    const brandSelfTheme = this.layoutConfigService.getConfig('brand.self.theme') || '';
    if (brandSelfTheme === 'light') {
      result = 'logo-dark.png';
    }
    return `./assets/media/logos/${result}`;
  }

  /**
   * Check Menu is active
   * @param item: any
   */
  isMenuItemIsActive(item): boolean {
    if (item.submenu) {
      return this.isMenuRootItemIsActive(item);
    }

    if (!item.page) {
      return false;
    }

    return this.currentRouteUrl.indexOf(item.page) !== -1;
  }

  /**
   * Check Menu Root Item is active
   * @param item: any
   */
  isMenuRootItemIsActive(item): boolean {
    let result = false;

    for (const subItem of item.submenu) {
      result = this.isMenuItemIsActive(subItem);
      if (result) {
        return true;
      }
    }
    return false;
  }

  getSelectedLanguage() {
    return this.translations.getSelectedLanguage();
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseEnter(e: Event) {
    // check if the left aside menu is fixed
    if (document.body.classList.contains('aside-fixed')) {
      if (this.outsideTm) {
        clearTimeout(this.outsideTm);
        this.outsideTm = null;
      }

      this.insideTm = setTimeout(() => {
        // if the left aside menu is minimized
        if (document.body.classList.contains('aside-minimize') && KTUtil.isInResponsiveRange('desktop')) {
          // show the left aside menu
          this.render.removeClass(document.body, 'aside-minimize');
          this.render.addClass(document.body, 'aside-minimize-hover');
        }
      }, 50);
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseLeave(e: Event) {
    if (document.body.classList.contains('aside-fixed')) {
      if (this.insideTm) {
        clearTimeout(this.insideTm);
        this.insideTm = null;
      }

      this.outsideTm = setTimeout(() => {
        // if the left aside menu is expand
        if (document.body.classList.contains('aside-minimize-hover') && KTUtil.isInResponsiveRange('desktop')) {
          // hide back the left aside menu
          this.render.removeClass(document.body, 'aside-minimize-hover');
          this.render.addClass(document.body, 'aside-minimize');
        }
      }, 100);
    }
  }

  /**
   * Returns Submenu CSS Class Name
   * @param item: any
   */
  getItemCssClasses(item) {
    if (item.title === 'Charts') {
      return 'menu-item'
    } else {
      return 'menu-item-open'
    }
  }

  getItemAttrSubmenuToggle(item) {
    let toggle = 'hover';
    if (objectPath.get(item, 'toggle') === 'click') {
      toggle = 'click';
    } else if (objectPath.get(item, 'submenu.type') === 'tabs') {
      toggle = 'tabs';
    } else {
      // submenu toggle default to 'hover'
    }
    return toggle;
  }


  mobileMenuClose() {
    if (KTUtil.isBreakpointDown('lg') && this.offcanvas) { // Tablet and mobile mode
      this.offcanvas.hide(); // Hide offcanvas after general link click
    }
  }

  onNavigate(url: string) {
    window.open(url, '_blank');
  }

  menuTranslate(key) {
    return this.translate.instant(key);
  }

  isMenuCollapsed() {
    const isMinimised = document.body.classList.contains('aside-minimize-hover') && KTUtil.isInResponsiveRange('desktop')
    const asideWidth = document.getElementById('kt_aside_menu_wrapper').getBoundingClientRect().width;
    if  (asideWidth < 200 || isMinimised) {
      return false
    } else {
      return true
    }
  }

  menuCollapsed() {
    return document.body.classList.contains('aside-minimize-hover')
      || document.body.classList.contains('aside-minimize')
      && KTUtil.isInResponsiveRange('desktop');
  }

  downloadConnectApp() {
    if (navigator.userAgent.includes('Macintosh') || navigator.userAgent.includes('Mac OS')) {
      window.open('https://firebasestorage.googleapis.com/v0/b/esense-20ba5.appspot.com/o/connectApp%2FMindfieldConnect.dmg?alt=media&token=6f987202-436a-4f40-9df8-3f62e78711a1', '_blank')
    } else {
      window.open('https://firebasestorage.googleapis.com/v0/b/esense-20ba5.appspot.com/o/connectApp%2Fmindfield-connect-1.2.0.2.exe?alt=media&token=d86d3833-90fc-484f-bbdf-6c4aaecdc101', '_blank')
    }
  }
  getCreditsWidth() {
    const asideWidth = document.getElementById('kt_aside_menu_wrapper').getBoundingClientRect().width;
    if  (asideWidth) {
      return {
        width: asideWidth - 20 + 'px'
      }
    }
  }
}
