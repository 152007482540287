<section>
  <div class="main-wrap" [ngStyle]="setContentHeight()">
    <div class="main-heading prepare-item column" tourAnchor="prepare-tour">
      <h1 class="main-heading">{{'TRAINING_PREPARE.HEADING' | translate}}</h1>
      <img *ngIf="isSensorConnected" class="sensor-filter-green" src="assets/training-prepare/check.svg" alt="check">
      <img *ngIf="!isSensorConnected" class="sensor-filter-red" src="assets/training-prepare/close.svg" alt="close">
    </div>
    <div class="signal-check prepare-item column">
      <h1>{{'TRAINING_PREPARE.SIGNAL_QUALITY' | translate}}</h1>
      <div class="signal-info">
        <div class="item">
          <h4>{{'TRAINING_PREPARE.IMPEDANCE' | translate}} <span *ngIf="currentImpedance" class="current-signal" [ngClass]="currentImpedance &&currentImpedance < 20 ? 'sensor-good' : 'sensor-problem'">
        {{currentImpedance}} kΩ
      </span>
          </h4>
          <button (click)="openInfoDialogImpedance()" class="info-dialog-button">
      <span class="material-symbols-outlined">
      info
    </span>
          </button>
        </div>
        <div class="item">
          <h4>{{'TRAINING_PREPARE.MUSCLE' | translate}} <span *ngIf="currentArtefakt" class="current-signal" [ngClass]="currentArtefakt && currentArtefakt < 6 ? 'sensor-good' : 'sensor-problem'">
        {{currentArtefakt}} µV
      </span>
          </h4>
          <button (click)="openInfoDialogArtefact()" class="info-dialog-button">
      <span class="material-symbols-outlined">
      info
    </span>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="routerUrl === '/training-screen'" class="select-training prepare-item column">
      <div *ngIf="(currentArtefakt && currentArtefakt < 6 && currentImpedance && currentImpedance < 20) || isDemoMode">
        <div class="supervisor-training-route" *ngIf="currentUser?.role !== 'normal' && currentUser?.role !== 'premium'">
          <button mat-raised-button color="primary" (click)="toTraining(undefined)">{{'TRAINING_PREPARE.TO_TRAINING' | translate}}</button>
        </div>
        <h1 *ngIf="routerUrl === '/training-screen' && !isSupervisorTemplate(userSurveyTrainings)" >{{'TRAINING_PREPARE.SELECT_TRAINING' | translate}}</h1>
        <h1 *ngIf="routerUrl === '/training-screen' && isSupervisorTemplate(userSurveyTrainings) && currentUser?.role === 'normal'">{{'TRAINING_PREPARE.CUSTOM_TRAINING' | translate}}</h1>
        <h1 *ngIf="routerUrl === '/test' && currentUser?.role === 'normal'">{{'TRAINING_PREPARE.SELECT_TEST' | translate}}</h1>
        <div class="trainings-list" >
          <div class="user-survey-item" *ngIf="!isSupervisorTemplate(userSurveyTrainings)">
          </div>
          <div class="list-of-trainings">
            <div class="trainings-wrap" [ngClass]="{'trainings-wrap-limited' : !currentUser?.monthSessions.catStartSession}">
              <div class="item" *ngFor="let item of sortTrainings(userSurveyTrainings);let i = index">
                <p class="to-training" (click)="toTraining(item)">{{item | templateTranslate}}</p>
                <div class="supervisor-training" *ngIf="item.supervisorName">
                  <span class="material-symbols-outlined">
                    person
                  </span>
                  <p>{{(item.supervisorName)}}</p>
                </div>
                <div class="creation-time">
                  <span class="material-symbols-outlined">
                  calendar_month
                  </span>
                  <p>{{transformDate(item.id)}}</p>
                </div>
                <div class="training-info">
                  <div class="expand-button" (click)="setExpandIndex(i)">
                    <span class="material-symbols-outlined">
                    info
                  </span>
                  </div>
                    <div class="info-list" [@detailExpand]="i == infoExpandIndex ? 'expanded' : 'collapsed'">
                      <div class="info-item" *ngFor="let info of item.data; let i = index">
                        <ng-container *ngIf="info.checked">
                          <p>{{i + 1}})</p>
                          <p><b>{{info.selectedOption | translate}}</b></p>
                          <p>{{info.trainingProtocol | titlecase}}</p>
                          <p *ngIf="info.trainingProtocol === 'amplitude'">
                            {{getRatioName(info?.frequencyOne) | uppercase}}
                          </p>
                          <p *ngIf="info.trainingProtocol === 'ratio'">
                            {{getRatioName(info?.frequencyOne) | uppercase}} / {{getRatioName(info?.frequencyTwo) | uppercase}}
                          </p>
                          <p>{{getThresholdTranslate(info?.threshHoldType)}}</p>
                          <p class="no-margin">,{{info.length}} min.</p>
                        </ng-container>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

<!--        <div class="text-bottom" *ngIf="routerUrl === '/training-screen'">-->
<!--          <p>{{'TRAINING_PREPARE.TRAINING_MESSAGE' | translate}}</p>-->
<!--        </div>-->
        <div class="sessions-count" *ngIf="routerUrl === '/training-screen'">
          <h2>{{'TRAINING_PREPARE.SESSIONS_COUNT' | translate}}</h2>
          <p>{{'TRAINING_PREPARE.LEFT_PER_MONTH' | translate}} <span class="limit-value">{{20 - currentUser?.monthSessions.sessionsCount}}</span></p>
          <p *ngIf="!currentUser?.monthSessions.catStartSession && currentUser?.monthSessions.sessionsCount !== 20">{{'TRAINING_PREPARE.NEXT_SESSION_AVAILABLE' | translate}} <span class="limit-value">{{convertHoursToHHMM(currentUser?.monthSessions.nextSessionAvailableInHours)}}</span></p>
          <p *ngIf="currentUser?.monthSessions.sessionsCount === 15">{{'TRAINING_PREPARE.LIMIT_REACHED' | translate}}</p>
        </div>

      </div>
    </div>
    <div class="warning prepare-item" *ngIf="(currentArtefakt > 6 || currentImpedance > 20) && !isDemoMode">
      <h4>{{'TRAINING_PREPARE.WARNING' | translate}}</h4>
    </div>
  </div>
</section>
