import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deviceId'
})
export class DeviceIdPipe implements PipeTransform {

  transform(inputString: any, ...args: unknown[]): unknown {
    const parts = inputString.split('_');
    if (parts.length >= 3) {
      return parts.slice(2).join('_');
    } else {
      return '';
    }
  }

}
